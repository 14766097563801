$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --buffer: 5em;

  position: relative;
  overflow: hidden;

  &.text {
    display: inline-block;
    margin-bottom: -0.1em;
  }
}
