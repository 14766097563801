/* @import "https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap"; */

@font-face {
  ascent-override: 90%;
  font-display: swap;
  font-family: poly-sans;
  font-style: normal;
  font-weight: 400;
  src: url("https://ik.imagekit.io/metropolis/hub-assets/fonts/PolySans-Slim.woff2")
    format("woff2");
}
