$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: relative;
}

.options {
  display: grid;
  grid-gap: calc(var(--grid-gap) * 0.5);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @include mediaBreakpointDown("xs") {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
