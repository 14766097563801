$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: relative;

  &.text {
    display: inline-block;
  }

  &.text .child {
    display: inline-block;
    padding-bottom: 0.05em;
  }
}

.child {
  position: relative;
  transform-origin: 0% 100%;
}
