$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  min-width: 6em;

  &.busy .label {
    opacity: 0;
  }
}

.display {
  display: grid;

  > * {
    grid-area: 1 / 1;
  }
}

.spinner {
  --spinner-color: currentcolor;
}
