$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.submit {
  min-width: 8em;
}

.submitWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submitError {
  padding: 0.5em 0 0;
  color: var(--color-d-accent-b);
  font-size: 0.9em;
}
