$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-transition-duration: var(
    --fade-up-transition-duration,
    var(--duration-750ms)
  );
  --local-transition-delay: var(
    --fade-up-transition-delay,
    var(--duration-100ms)
  );
  --local-distance: var(--fade-up-distance, 40px);

  position: relative;
  opacity: 0;
  transform: translate(0, var(--local-distance));

  @keyframes animation-fade-up {
    0% {
      opacity: 0;
      transform: translate(0, var(--local-distance));
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &.intersect {
    opacity: 1;
    transform: none;
    transition:
      opacity var(--local-transition-duration)
        calc(
          var(--local-transition-delay) +
            var(--intersection-animation-delay, 0s)
        ),
      transform var(--local-transition-duration)
        calc(
          var(--local-transition-delay) +
            var(--intersection-animation-delay, 0s)
        );
  }
}
