$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  padding: 0 2rem;
  gap: 1rem;
  pointer-events: all;

  @include mediaBreakpointDown("sm") {
    padding: 0 1rem;
  }

  @include mediaBreakpointDown("xs") {
    padding: 0 0.5rem;
  }
}

.title {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-size: var(--font-size-md);
  line-height: 1;
  text-transform: uppercase;
}

.close {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: -0.35rem;
  text-transform: uppercase;

  @include mediaBreakpointDown("sm") {
    margin-top: -0.55rem;
  }
}
