@use "sass:map";
@use "../constants/breakpoints" as constants;

@mixin mediaBreakpointDown($name) {
  $max: map.get(constants.$breakpoints, $name);

  @if $max and $max != 0 {
    @media (max-width: $max - 1 + "px") {
      @content;
    }
  } @else if $name == "default" {
    @content;
  }
}

@mixin mediaBreakpointUp($name) {
  $min: map.get(constants.$breakpoints, $name);

  @if $min and $min != 0 {
    @media (min-width: $min + "px") {
      @content;
    }
  } @else if $name == "default" {
    @content;
  }
}
