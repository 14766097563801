$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.field {
  position: relative;
  z-index: 0;
  height: var(--form-field-height);
  appearance: none;
  color: var(--form-field-color);
  font-size: var(--form-field-font-size);
  transition:
    border-color var(--form-field-transition-duration-hover) var(--ease-out),
    color var(--form-field-transition-duration-hover) var(--ease-out),
    background-color var(--form-field-transition-duration-hover) var(--ease-out);

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    inset: 0;
  }

  &::before {
    border-radius: var(--form-field-border-radius);
    border-top: solid var(--form-field-border-width)
      var(--form-field-border-color);
    border-bottom: solid var(--form-field-border-width)
      var(--form-field-border-color);
    background: var(--form-field-color-bg);
    mask-image: var(--form-field-mask);
    transition:
      border-color var(--form-field-transition-duration-hover) var(--ease-out),
      color var(--form-field-transition-duration-hover) var(--ease-out),
      background-color var(--form-field-transition-duration-hover)
        var(--ease-out);
  }

  /* &::after {
    @include grainTexture(0.05);
  } */

  @mixin hoverState {
    --form-field-color: var(--form-field-color-hover);
    --form-field-border-color: var(--form-field-border-color-hover);
    --form-field-color-accent: var(--form-field-color-accent-hover);
  }

  @include hoverFocus {
    @include hoverState;
  }

  &[data-h] {
    @include hoverState;
  }

  &:checked {
    --form-field-border-color: var(--form-field-border-color-checked);
  }

  &[type="checkbox"] {
    --form-field-height: var(--form-checkbox-height);

    width: var(--form-field-height);
    flex-grow: 0;
    flex-shrink: 0;

    &::before {
      border-radius: var(--form-field-border-radius);
      border-right: solid var(--form-field-border-width)
        var(--form-field-border-color);
      border-left: solid var(--form-field-border-width)
        var(--form-field-border-color);
      mask-image: none;
    }
  }
}
