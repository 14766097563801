$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.content {
  position: relative;
}

.options {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 2rem auto 0;
  grid-gap: 0.5rem;
  list-style: none;

  li {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0;

    a,
    button {
      min-width: 100%;
    }
  }

  @include mediaBreakpointDown("sm") {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
