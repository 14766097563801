$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  text-align: left;

  .label {
    position: relative;
    display: grid;
    align-items: center;
    align-items: flex-start;
    cursor: pointer;
    font-size: var(--form-field-font-size);
    grid-gap: var(--form-checkbox-padding);
    grid-template: 1fr / min-content 1fr;
    grid-template-areas: "checkbox label";
    user-select: none;

    > span {
      position: relative;
      min-height: 100%;
      align-items: center;
      color: var(--form-standalone-label-color);
      font-size: var(--form-label-font-size);
      grid-area: label;
    }

    a {
      color: var(--color-d);
      text-decoration: underline;
      transition: color var(--200ms) var(--ease-out);

      @media (hover: hover) {
        &:hover {
          color: var(--color-d-accent-a);
        }
      }
    }
  }

  .field {
    position: relative;
    width: var(--form-radio-size);
    height: var(--form-radio-size);
    border: var(--form-field-border-width) solid var(--form-box-border-color);
    border-radius: 50%;
    appearance: none;
    cursor: pointer;
    grid-area: checkbox;
    transition: border-color var(--form-field-transition-duration-hover)
      var(--ease-out);

    @media (hover: hover) {
      &:hover {
        --form-box-border-color: var(--form-box-border-color-hover);

        ~ .bg {
          --form-field-background-color: var(
            --form-field-background-color-hover
          );
          --form-field-background-opacity: var(
            --form-field-background-opacity-hover
          );
        }
      }
    }

    &::before {
      position: absolute;
      width: var(--form-radio-inner-size);
      height: var(--form-radio-inner-size);
      border-radius: 50%;
      margin: auto;
      background-color: var(--form-checkbox-color);
      box-shadow: 0 0 2px transparent;
      content: "";
      inset: 0;
      opacity: 0;
      transform: scale(0.5);
      transition:
        opacity var(--form-field-transition-duration-hover) var(--ease-out),
        transform var(--form-field-transition-duration-hover) var(--ease-out);
    }

    &:checked {
      &::before {
        opacity: 1 !important;
        transform: scale(1);
      }
    }

    &:focus {
      outline: 0;
    }

    &:focus-visible {
      outline: 0;

      --form-box-border-color: var(--form-box-border-color-focus) !important;
    }
  }

  &.checked {
    &,
    * {
      --form-box-border-color: var(--form-box-border-color-checked) !important;
    }

    .bg {
      --form-field-background-color: var(
        --form-field-background-color-focus
      ) !important;
      --form-field-background-opacity: var(
        --form-field-background-opacity-focus
      ) !important;
    }
  }

  .bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    background-color: hsla(
      var(--form-field-background-color),
      var(--form-field-background-opacity)
    );
    grid-area: checkbox;
    transition: background-color var(--form-field-transition-duration-hover)
      var(--ease-out);

    &::before {
      position: absolute;
      background: linear-gradient(to top, var(--color-c), transparent 50%);
      content: "";
      inset: 0;
      opacity: 0.05;
    }

    /* &::after {
      position: absolute;
      content: "";
      inset: 0;

      @include grainTexture(0.025);
    } */
  }
}
