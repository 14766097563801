$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.content {
  width: 100%;
  margin-bottom: 1rem;

  h1 {
    font-size: 1.6em;
  }

  h2 {
    font-size: 1.4em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  a {
    color: var(--color-purple-200);
    text-decoration: underline;
    transition: color var(--250ms) var(--ease-out);

    @include hoverFocus {
      color: var(--color-purple-400);
    }
  }

  ul,
  ol {
    padding: 0 0 0 2em;
    margin: 0;

    li {
      margin-bottom: 1rem;
    }
  }

  table {
    margin-bottom: 1rem;
    border-collapse: collapse;

    @include mediaBreakpointDown("sm") {
      font-size: 0.8em;
    }

    ol,
    ul {
      padding: 0 0 0 1em;
      margin: 0;
    }
  }

  table,
  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  td,
  th {
    padding: 0.5rem;
  }

  &:only-child,
  > *:last-child {
    margin-bottom: 0;
  }
}
