$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.form {
  --form-color-alert: var(--color-gray-300);
  --form-color-warn: var(--color-black);
  --form-color-alert-icon: var(--color-white);
  --form-field-font-size: 1em;
  --local-primary-color-hsl: 0 0 0;

  /* Field */
  --form-field-color-bg: black;
  --form-field-background-color: var(--local-primary-color-hsl);
  --form-field-background-color-hover: var(--local-primary-color-hsl);
  --form-field-background-color-focus: var(--local-primary-color-hsl);
  --form-field-background-opacity: 0.05;
  --form-field-background-opacity-hover: 0.07;
  --form-field-background-opacity-focus: 0.09;
  --form-field-mask: var(--gradient-padding-mask-horizontal-soft);
  --form-field-color: hsla(var(--local-primary-color-hsl), 0.85);
  --form-field-color-hover: var(--color-a-accent-a);
  --form-field-color-accent: var(--color-d-accent-a);
  --form-field-color-accent-hover: var(--color-d);
  --form-field-padding-x: 0.5em;
  --form-field-height: 2.5em;
  --form-field-border-width: 1px;
  --form-field-highlight-width: 1px;
  --form-field-border-color: var(--color-gray-400);
  --form-field-border-color-hover: var(--color-gray-400);
  --form-field-border-color-focus: var(--color-gray-400);
  --form-field-border-color-checked: var(--color-gray-400);
  --form-field-border-color-warn: var(--color-gray-400);
  --form-field-border-color-ok: var(--color-gray-800);
  --form-box-border-color: var(--color-gray-400);
  --form-box-border-color-hover: var(--color-gray-800);
  --form-box-border-color-focus: var(--color-gray-800);
  --form-box-border-color-checked: var(--color-gray-800);
  --form-box-border-color-warn: var(--color-gray-800);
  --form-box-border-color-ok: var(--color-gray-800);
  --form-field-border-radius: 0.2em;
  --form-field-transition-duration-hover: var(--150ms);
  --form-field-placeholder-color: var(--color-gray-300);
  --form-field-required-color: var(--color-gray-300);
  --form-field-standalone-required-color: var(--color-gray-300);

  /* Label */
  --form-label-color: hsla(var(--local-primary-color-hsl), 0.65);
  --form-label-font-style: normal;
  --form-label-font-size: max(13px, 0.85em);
  --form-label-margin: 0.4rem;
  --form-standalone-label-color: var(--color-a);
  --form-fieldset-label-color: var(--color-d-accent-a);

  /* Checkbox */
  --form-checkbox-border-radius: min(var(--border-radius), 7px);
  --form-checkbox-size: 24px;
  --form-checkbox-color: var(--color-d-accent-a);
  --form-checkbox-padding: 0.75em;

  /* Radio */
  --form-radio-size: 24px;
  --form-radio-inner-size: 12px;
  --form-radio-color: var(--color-d-accent-a);
  --form-radio-padding: 0.75em;

  /* Select */
  --form-select-arrow-color: var(--color-d-accent-a);

  /* Group */
  --form-group-margin: 1rem;

  /* Separator */
  --form-spacer-size: 1.75rem;

  position: relative;
}
