@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin focus {
  &:focus-visible {
    @content;
  }
}

@mixin hoverFocus {
  @include hover {
    @content;
  }

  @include focus {
    @content;
  }
}
