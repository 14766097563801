html {
  margin: 0;
  font-size: var(--global-font-size);
  overscroll-behavior-y: none;
  scrollbar-color: #959595 transparent;
  scrollbar-width: thin;
  text-size-adjust: 100%;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 20%;
    border-radius: 2em;
    background: #959595;

    &:hover {
      background: #444;
    }
  }

  ::selection {
    background: #dedddd75;
    color: #030303;
  }
}

body {
  background: var(--color-background);
  color: var(--color-body) !important;
  font-family: var(--font-family-body) !important;
  -webkit-font-smoothing: auto !important;
  letter-spacing: 0.02em;
  line-height: 1.25 !important;
  overscroll-behavior: none;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: none;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

svg {
  overflow: visible;
}

a,
button {
  color: currentcolor;
  font-weight: 400;
  line-height: inherit;
  text-decoration: none;
  user-select: none;
}

button {
  padding: 0;
  border: 0;
  border-radius: 2em !important;
  margin: 0;
  appearance: none;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  -webkit-touch-callout: none;
  user-select: none;
}

h1 {
  font-weight: 400;
}

h2 {
  font-weight: 400;
}

p {
  margin-top: 0;
}

/* Pretty pricing */
.prpr--icon {
  width: 0.55em;
  margin-right: 0.2em;
}

.prsp--val,
.prpr--val {
  letter-spacing: 0.05em;
}
