@use "/src/app/styles/imports" as *;

:root {
  --font-family-heading: "poly-sans", sans-serif;
  --font-family-body: "poly-sans", sans-serif;
  --global-font-size: 14px;
  --header-height: 75px;
  --gap: 0.75rem;
  --letter-spacing-uppercase: 0.08em;

  /* Page config */
  --page-padding: 20px;
  --page-max-width: 1400px;

  /* Section config */
  --layout-section-gap: 10rem;

  /* Colors */
  --color-gray-900: #f7f8fa;
  --color-gray-800: #e7e9ee;
  --color-gray-700: #d3d7df;
  --color-gray-400: #aeb2ba;
  --color-gray-300: #838890;
  --color-gray-200: #4f5256;
  --color-gray-150: #222327;
  --color-gray-100: #151618;
  --color-purple-400: #ae7ff5;
  --color-purple-200: #9352f4;
  --color-dark-purple-200: #423288;
  --color-white: #fff;
  --color-black: #000;

  /* Color mappings */
  --color-background: var(--color-black);
  --color-body: var(--color-white);
  --color-link-primary: var(--color-gray-700);
  --color-link-primary-hover: var(--color-white);
  --color-link-primary-inverted: var(--color-black);
  --color-link-primary-inverted-hover: var(--color-gray-300);
  --text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  /* Font sizes */
  --font-size-xxl: min(11vw, 13rem);
  --font-size-xl: min(10vw, 8rem);
  --font-size-lg: min(6vw, 4rem);
  --font-size-md: min(5vw, 3rem);
  --font-size-sm: max(1.2rem, min(1.7vw, 1.4rem));
  --title-text-transform: uppercase;
  --subtitle-text-transform: none;

  /* Transitions */
  --1000ms: 1s;
  --750ms: 0.75s;
  --500ms: 0.5s;
  --400ms: 0.5s;
  --350ms: 0.35s;
  --300ms: 0.35s;
  --250ms: 0.25s;
  --200ms: 0.2s;
  --150ms: 0.15s;
  --100ms: 0.1s;
  --50ms: 0.05s;
  --ease-in: #{ease(cubic-in)};
  --ease-out: #{ease(cubic-out)};
  --ease-in-out: #{ease(cubic-in-out)};
  --gradient-cross: linear-gradient(
      to top left,
      transparent 0%,
      transparent calc(50% - 1px),
      currentcolor 50%,
      transparent calc(50% + 1px),
      transparent 100%
    ),
    linear-gradient(
      to top right,
      transparent 0%,
      transparent calc(50% - 1px),
      currentcolor 50%,
      transparent calc(50% + 1px),
      transparent 100%
    );

  @include mediaBreakpointDown("sm") {
    --font-size-xxl: min(17vw, 17rem);
    --font-size-xl: min(10vw, 6rem);
    --font-size-lg: min(7vw, 5rem);
    --font-size-md: min(5vw, 3rem);
    --font-size-sm: max(1rem, min(4vw, 1.5rem));
  }

  @include mediaBreakpointUp("xs") {
    --global-font-size: 15px;
  }

  @include mediaBreakpointUp("sm") {
    --page-padding: 40px;
  }

  @include mediaBreakpointUp("md") {
    --page-padding: 80px;
  }
}
