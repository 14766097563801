$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-padding-x: 1.25em;
  --local-icon-size: 1.2em;
  --local-background-color: var(--color-gray-700);
  --local-background-color-hover: var(--color-white);
  --local-background-color-press: var(--color-white);
  --local-background-color-active: var(--color-white);
  --local-color: var(--color-black);
  --icon-before-offset: 0px;
  --icon-before-opacity: 0;
  --content-shift: 0px;

  position: relative;
  z-index: 0;
  display: flex;
  min-height: 3em;
  align-items: center;
  justify-content: center;
  padding: 0 var(--local-padding-x);
  border: none;
  border-radius: 2em;
  color: var(--local-color);
  font-weight: 500;
  gap: 0.75em;

  &.secondary {
    --local-color: var(--color-white);

    &::before {
      border: solid 2px var(--local-background-color);
      background-color: transparent;
      transition: stroke-color var(--250ms) var(--ease-out);
    }
  }

  &.tertiary {
    --local-background-color: var(--color-purple-200);
    --local-background-color-hover: var(--color-purple-400);
    --local-background-color-press: var(--color-purple-400);
    --local-background-color-active: var(--color-purple-400);
    --local-color: var(--color-white);
  }

  @include hoverFocus {
    --local-background-color: var(--local-background-color-hover);
    --icon-before-offset: var(--local-padding-x);
    --icon-before-opacity: 1;

    &.hasIcon {
      --content-shift: calc(var(--local-icon-size) + 0.5em);

      .icon.before {
        transition-delay: var(--100ms);
      }
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    border-radius: inherit;
    background-color: var(--local-background-color);
    content: "";
    inset: 0;
    transition: background-color var(--250ms) var(--ease-out);
  }

  &.press {
    --local-background-color: var(--local-background-color-press);

    &::before {
      transform: scale(0.98);
    }
  }

  &.active {
    --local-background-color: var(--local-background-color-active);
  }

  &.size-std {
    font-size: 1rem;

    @include mediaBreakpointDown("sm") {
      font-size: 0.9rem;
    }
  }

  &.size-xs {
    --local-padding-x: 1em;

    border-radius: 0.75em;
    font-size: 0.8rem;
  }

  &.size-sm {
    --local-padding-x: 1em;

    font-size: 0.9rem;
  }

  &.size-xl {
    font-size: 1.25rem;

    @include mediaBreakpointDown("sm") {
      font-size: 1rem;
    }
  }

  &.size-xxl {
    font-size: 1.5rem;

    @include mediaBreakpointDown("sm") {
      font-size: 1.25rem;
    }
  }
}

.content {
  transform: translate(var(--content-shift), 0);
  transition: transform var(--250ms) var(--ease-out);
}

.icon {
  width: var(--local-icon-size);
  height: auto;
  transform: translate(var(--icon-before-offset), 0);
  transition:
    transform var(--250ms) var(--ease-out),
    opacity var(--250ms) var(--ease-out);

  &.before {
    position: absolute;
    left: 0;
    opacity: var(--icon-before-opacity);
  }

  &.after {
    opacity: calc(1 - var(--icon-before-opacity));
  }
}

.spinner {
  --spinner-color: currentcolor;
}
