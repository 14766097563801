$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  text-align: left;

  &.style-checkbox {
    .label {
      position: relative;
      display: grid;
      align-items: center;
      align-items: flex-start;
      cursor: pointer;
      font-size: var(--form-field-font-size);
      grid-gap: var(--form-checkbox-padding);
      grid-template: 1fr / min-content 1fr;
      grid-template-areas: "checkbox label";
      user-select: none;

      > span {
        position: relative;
        min-height: 100%;
        align-items: center;
        margin-top: 0.2em;
        color: var(--form-standalone-label-color);
        font-size: var(--form-label-font-size);
        grid-area: label;
      }

      a {
        color: var(--color-d);
        text-decoration: underline;
        transition: color var(--200ms) var(--ease-out);

        @media (hover: hover) {
          &:hover {
            color: var(--color-d-accent-a);
          }
        }
      }
    }

    .field {
      position: relative;
      width: var(--form-checkbox-size);
      height: var(--form-checkbox-size);
      border: var(--form-field-border-width) solid var(--form-box-border-color);
      border-radius: var(--form-checkbox-border-radius);
      appearance: none;
      cursor: pointer;
      grid-area: checkbox;
      transition: border-color var(--form-field-transition-duration-hover)
        var(--ease-out);

      @media (hover: hover) {
        &:hover {
          --form-box-border-color: var(--form-box-border-color-hover);

          ~ .bg {
            --form-field-background-color: var(
              --form-field-background-color-hover
            );
            --form-field-background-opacity: var(
              --form-field-background-opacity-hover
            );
          }
        }
      }

      &::before {
        position: absolute;
        background-color: var(--form-checkbox-color);
        clip-path: polygon(
          44.32% 83.64%,
          6.38% 50.18%,
          14.18% 41.35%,
          42.96% 66.74%,
          84.22% 16.52%,
          93.31% 23.99%
        );
        content: "";
        inset: 4px;
        mask-image: linear-gradient(to right, transparent 0% 50%, white 50%);
        mask-position: 0 0;
        mask-size: 200% 100%;
        opacity: 0.5;
        transition:
          opacity var(--form-field-transition-duration-hover) var(--ease-out),
          mask-position var(--form-field-transition-duration-hover)
            ease(cubic-in-out),
          transform var(--form-field-transition-duration-hover) var(--ease-out);
      }

      &:checked {
        &::before {
          mask-position: -100% 0;
          opacity: 1 !important;
        }
      }

      &:focus {
        outline: 0;
      }

      &:focus-visible {
        outline: 0;

        --form-box-border-color: var(--form-box-border-color-focus) !important;
      }
    }

    &.checked {
      &,
      * {
        --form-box-border-color: var(
          --form-box-border-color-checked
        ) !important;
      }

      .bg {
        --form-field-background-color: var(
          --form-field-background-color-focus
        ) !important;
        --form-field-background-opacity: var(
          --form-field-background-opacity-focus
        ) !important;
      }
    }

    .bg {
      position: absolute;
      z-index: -1;
      width: 100%;
      border-radius: var(--form-checkbox-border-radius);
      aspect-ratio: 1 / 1;
      background-color: hsla(
        var(--form-field-background-color),
        var(--form-field-background-opacity)
      );
      grid-area: checkbox;
      transition: background-color var(--form-field-transition-duration-hover)
        var(--ease-out);

      &::before {
        position: absolute;
        background: linear-gradient(to top, var(--color-c), transparent 50%);
        content: "";
        inset: 0;
        opacity: 0.05;
      }

      /* &::after {
        position: absolute;
        content: "";
        inset: 0;

        @include grainTexture(0.025);
      } */
    }
  }

  &.style-button {
    .label {
      position: relative;
      display: grid;
      cursor: pointer;
      grid-template: 1fr / 1fr;
      grid-template-areas: "checkbox";
    }

    &.checked {
      &,
      * {
        --form-box-border-color: var(
          --form-box-border-color-checked
        ) !important;
      }

      .bg {
        opacity: 1;

        --form-field-background-color: var(--color-a-hsl) !important;
        --form-field-background-opacity: 1 !important;
      }

      span {
        color: var(--color-f);
      }
    }

    .bg,
    .label > span,
    .field {
      grid-area: 1 / 1;
    }

    .bg {
      position: absolute;
      z-index: -1;
      border: var(--form-field-border-width) solid var(--form-box-border-color);
      border-radius: calc(var(--form-field-border-radius) * 0.8);
      background-color: hsla(
        var(--form-field-background-color),
        var(--form-field-background-opacity)
      );
      grid-area: checkbox;
      inset: 0;
      transition:
        background-color var(--form-field-transition-duration-hover)
          var(--ease-out),
        border-color var(--form-field-transition-duration-hover) var(--ease-out);

      &::before {
        position: absolute;
        background: linear-gradient(to top, var(--color-c), transparent 50%);
        content: "";
        inset: 0;
        opacity: 0.05;
      }
    }

    .field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      appearance: none;
      cursor: pointer;

      @media (hover: hover) {
        &:hover:not(:checked) {
          --form-box-border-color: var(--form-box-border-color-hover);

          ~ .bg {
            --form-box-border-color: var(--form-box-border-color-hover);
            --form-field-background-color: var(
              --form-field-background-color-hover
            );
            --form-field-background-opacity: var(
              --form-field-background-opacity-hover
            );
          }

          ~ span {
            color: hsla(var(--color-a-hsl), 1);
          }
        }
      }
    }

    span {
      position: relative;
      display: inline-flex;
      min-width: 3em;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0.6rem;
      border-radius: 2em;
      color: hsla(var(--color-a-hsl), 0.5);
      cursor: pointer;
      font-size: 0.95rem;
      font-weight: 500;
      grid-gap: 0.33em;
      pointer-events: none;
      text-align: center;
      transition:
        border-color var(--form-field-transition-duration-hover) var(--ease-out),
        color var(--form-field-transition-duration-hover) var(--ease-out);
      user-select: none;

      svg {
        width: 1em !important;
        opacity: 0.75;
      }
    }
  }
}
