$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-color: var(--color-link-primary);
  --local-color-hover: var(--color-link-primary-hover);
  --local-color-dot: var(--color-purple-200);
  --local-icon-size: 1rem;
  --local-icon-transform: calc(var(--local-icon-size) + 3px);
  --local-icon-opacity: 0;
  --local-bar-size: var(--nav-link-bar-size, 2px);

  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0.4em 0;
  margin-right: var(--local-icon-transform);
  color: currentcolor;
  color: var(--local-color);
  cursor: pointer;
  font-weight: 500;
  transform: translateZ(0);
  transition: color var(--400ms) ease(cubic-in-out);

  &.noMargin {
    margin-right: 0 !important;
  }

  > * {
    pointer-events: none;
  }

  &.invert {
    --local-color: var(--color-link-primary-inverted);
    --local-color-hover: var(--color-link-primary-inverted-hover);
  }

  @media (hover: hover) {
    &:hover {
      --local-color: var(--local-color-hover);
    }
  }

  &.out {
    .bar::before {
      transform: translate(calc(100% + 1px), 0);
      transition: transform var(--300ms) ease(cubic-in-out);
    }
  }

  &.in {
    --local-text-roll: 1;

    .bar::before {
      transform: translate(0%, 0);
      transition: transform var(--300ms) ease(cubic-in-out);
    }

    &.hasIcon {
      --local-content-offset: translate(var(--local-icon-transform), 0);
      --local-icon-opacity: 1;
    }
  }

  &.active-style-dot {
    &.active {
      .label::after {
        display: block;
      }
    }
  }
}

.content {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  transform: var(--local-content-offset, none);
  transition: transform var(--250ms) var(--ease-out);
}

.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  width: var(--local-icon-size);
  align-items: center;
  margin: auto 0;

  svg {
    width: var(--local-icon-size);
    height: auto;
    opacity: var(--local-icon-opacity);
    transform: translate(calc(-100% + var(--local-icon-opacity) * 100%), 0);
    transition:
      transform var(--250ms) var(--ease-out),
      opacity var(--250ms) var(--ease-out);
  }
}

.bar {
  position: absolute;
  top: calc(100% + 0.1em);
  left: 0;
  overflow: hidden;
  width: min(100%, var(--bar-width));
  height: var(--local-bar-size);
  border-radius: 2px;
  backface-visibility: hidden;

  &::before {
    position: absolute;
    border-radius: inherit;
    background-color: currentcolor;
    content: "";
    inset: 0;
    transform: translate(calc(-100% - 1px), 0);
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::after {
    display: none;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--local-color-dot);
    content: "";
  }
}
