$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.alert {
  --font-size-alert: var(--alert-font-size, 0.85em);

  overflow: hidden;
  width: fit-content;
  max-width: 30em;
  margin: 0 auto;
  text-align: center;

  @include mediaBreakpointDown("md") {
    --font-size-alert: var(--alert-font-size, 0.7em);
  }

  @include mediaBreakpointDown("sm") {
    --font-size-alert: var(--alert-font-size, 0.65em);
  }

  @include mediaBreakpointDown("xs") {
    --font-size-alert: var(--alert-font-size, 0.6em);
  }

  &.align-left {
    margin: 0;
    text-align: left;
  }
}

.alertContent {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.5em 0;
  margin: 0.2em 0 0;
  color: var(--form-color-alert, var(--color-gray-300));
  font-size: var(--font-size-alert);
  font-style: italic;
  font-weight: 400;
}

.alertIcon {
  display: flex;
  width: 1.1em;
  height: 1.1em;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.5em;
  background-color: var(--form-color-warn, var(--color-black));

  svg {
    width: 0.8em;
    color: var(--form-color-alert-icon, var(--color-white));
  }
}
