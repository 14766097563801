$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.fieldset {
  padding: 0;
  border: none;
  margin: 0;
  text-align: left;

  legend {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    padding-top: 0;
    padding-bottom: calc(var(--form-label-margin) * 4);
    margin: 0;
    color: var(--form-fieldset-label-color);
    font-size: var(--form-label-font-size);
    font-weight: 400;
    grid-area: label;
    transition: color var(--form-field-transition-duration-hover)
      var(--ease-out);

    > span {
      position: relative;
      display: flex;
      align-items: center;

      span {
        position: relative;
        margin-left: 0.5em;
        color: var(--form-field-standalone-required-color);
        font-size: 0.85em;
      }
    }
  }
}
