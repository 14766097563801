$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
@keyframes animation-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  --size: var(--spinner-size, 1.3em);
  --thickness: var(--spinner-thickness, 3px);
  --color: var(--spinner-color, currentcolor);
  --position: var(--spinner-position, relative);
  --transition-duration: var(--spinner-transition-duration, var(--200ms));

  position: var(--position);
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  opacity: 0;
  transform: translateZ(0);
  transition: opacity var(--transition-duration) ease(cubic-out);

  &::before {
    position: relative;
    width: var(--size);
    border-radius: 50%;
    animation: animation-spin 0.5s linear infinite;
    aspect-ratio: 1;
    background: conic-gradient(from 0.25turn, transparent, var(--color));
    content: "";
    mask-image: radial-gradient(
      closest-side,
      transparent 0% calc(100% - var(--thickness) - 1px),
      var(--color) calc(100% - var(--thickness))
    );
    pointer-events: none !important;
    transform: translateZ(0);
  }

  &[data-v="true"] {
    opacity: 1;
  }
}
