$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-font-size-primary-links: 1.5rem;
  --local-font-size-secondary-links: 1rem;
  --local-max-width: 25em;
  --local-padding: 2rem;
  --local-bg-opacity: 0;
  --local-card-transition-duration: var(--300ms);

  position: absolute;
  inset: 0;
  pointer-events: none;

  &::before {
    position: absolute;
    background: var(--color-gray-200);
    content: "";
    inset: 0;
    opacity: var(--local-bg-opacity);
    transition:
      opacity var(--300ms) var(--ease-out),
      visibility var(--300ms) var(--ease-out);
    visibility: hidden;
  }

  @include mediaBreakpointUp("lg") {
    display: none;
  }

  @include mediaBreakpointDown("sm") {
    --local-font-size-primary-links: max(1.1rem, min(4.4vw, 2.9vh));
    --local-font-size-secondary-links: max(1rem, min(4vw, 2.5vh));
    --local-max-width: none;

    &.active {
      --local-bg-opacity: 1;

      pointer-events: all;
    }

    &::before {
      visibility: visible;
    }
  }

  @include mediaBreakpointDown("xxs") {
    --local-padding: 1.5rem;
  }

  &.active {
    --local-card-transition-duration: var(--300ms);

    &::before {
      transition: opacity var(--300ms) var(--ease-out);
    }

    .card,
    .buttonCard {
      opacity: 1;
      pointer-events: all;
      transform: translate(0, 0);
    }
  }
}

.inset {
  position: absolute;
  inset: var(--header-height) var(--page-padding) var(--page-padding);
}

.display {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  max-width: var(--local-max-width);
  flex-direction: column;
  gap: 1rem;
}

.card {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  padding: var(--local-padding);
  border-radius: 1rem;
  background-color: var(--color-white);
  box-shadow:
    0 8px 32px 0 rgb(0 0 0 / 12%),
    0 0 1px 0 rgb(0 0 0 / 8%);
  color: var(--color-black);
  opacity: 0;
  transform: translate(0, 5rem) rotate(-10deg);
  transform-origin: 100% 0%;
  transition:
    opacity calc(var(--local-card-transition-duration) + var(--index) * 0.025s)
      calc(var(--index) * 0.02s) var(--ease-out),
    transform
      calc(var(--local-card-transition-duration) + var(--index) * 0.025s)
      calc(var(--index) * 0.02s) var(--ease-out);

  @include mediaBreakpointDown("sm") {
    flex-grow: 1;
  }
}

.buttonCard {
  border-radius: 1rem;
  box-shadow:
    0 8px 32px 0 rgb(0 0 0 / 12%),
    0 0 1px 0 rgb(0 0 0 / 8%);
  opacity: 0;
  transform: translate(0, 5rem) rotate(-10deg);
  transform-origin: 100% 0%;
  transition:
    opacity calc(var(--local-card-transition-duration) + var(--index) * 0.025s)
      calc(var(--index) * 0.02s) var(--ease-out),
    transform
      calc(var(--local-card-transition-duration) + var(--index) * 0.025s)
      calc(var(--index) * 0.02s) var(--ease-out);

  .button {
    width: 100%;
    justify-content: space-between;
    padding: calc(var(--local-padding) * 0.75) var(--local-padding);
    border-radius: inherit;
    font-size: var(--local-font-size-primary-links);
  }
}

.nav {
  flex-shrink: 1;
}

.content {
  position: relative;
  display: flex;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  gap: 0;

  @include mediaBreakpointDown("sm") {
    justify-content: space-between;
  }
}

.primary,
.secondary,
.sub {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;

  a {
    font-weight: 400;
  }
}

.primary {
  padding: 0 0 0.5rem;
  font-size: var(--local-font-size-primary-links);

  --nav-link-bar-size: 3px;
}

.sub {
  padding: 0 0 0.5rem 0.5rem;

  --nav-link-bar-size: 3px;
}

.secondary {
  padding: 0 0 1rem;
  font-size: var(--local-font-size-secondary-links);
}

.social {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
  list-style: none;

  svg {
    width: 1.5rem;
    height: auto;
  }

  a {
    color: var(--color-link-primary-inverted);
    transition: color var(--250ms) ease(cubic-in-out);

    @media (hover: hover) {
      &:hover {
        color: var(--color-link-primary-inverted-hover);
      }
    }
  }
}
