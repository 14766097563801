$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: var(--form-label-margin);
  color: var(--form-label-color);
  font-size: var(--form-label-font-size);
  font-style: var(--form-label-font-style);
  font-weight: 500;
  transition: color var(--form-field-transition-duration-hover) var(--ease-out);

  &.size-lg {
    font-size: calc(var(--form-label-font-size) * 1.1);
  }
}
