$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-min-height: calc(var(--form-field-height) * 0.75);

  position: relative;

  &.size-lg {
    --local-min-height: var(--form-field-height);

    .tab {
      font-size: 1.1em;
    }
  }
}

.wrapper {
  min-height: var(--local-min-height);
  padding: 3px;
  grid-area: field;
}

.field {
  position: relative;
  display: grid;
  min-height: 100%;
  grid-auto-flow: column;
  grid-gap: 0em;
}

.tab {
  position: relative;
}

.bg {
  position: relative;
  border-radius: inherit;
  border-radius: calc(var(--form-field-border-radius) * 0.8);
  background: hsla(var(--color-a-hsl), 0.15);
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}
