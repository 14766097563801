$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.error {
  text-align: left;
}

.spinner {
  --spinner-size: 2rem;

  align-items: flex-start;
  justify-content: flex-start;
}
