$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.wrapper {
  --local-opacity-bg: 0;
  --modal-max-height: 500px;

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: var(--header-height) var(--page-padding) var(--page-padding);

  &:not(.render) {
    left: 100%;
    visibility: hidden;
  }

  &.active {
    --local-opacity-bg: 1;
  }

  &:not(.interactive) {
    .background {
      cursor: default !important;
    }

    .display,
    .display * {
      pointer-events: none;
    }
  }
}

.background {
  backdrop-filter: blur(4px) brightness(80%) saturate(120%) brightness(120%);
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.25);
  opacity: var(--local-opacity-bg);
  pointer-events: all;
  transition: opacity var(--250ms) var(--100ms) linear;

  &,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: "";
  }
}

.region {
  position: relative;
  display: flex;
  width: auto;
  max-width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.display {
  display: flex;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  pointer-events: none;
}
