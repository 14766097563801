$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 100%;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &.active {
    pointer-events: all;
  }

  &.render {
    left: 0;
  }
}

.background {
  position: absolute;
  inset: 0;
}

.status {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  gap: 1rem;
  inset: 0;
}

.bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  border-radius: 1em;
  border-radius: inherit;
  aspect-ratio: 1 / 0.2;
  background-color: var(--color-gray-200);
}

.progress {
  position: absolute;
  border-radius: inherit;
  background-color: var(--color-gray-800);
  inset: 0;
}

.logo {
  width: 3.5rem;
  height: auto;
  fill: var(--black);
}
