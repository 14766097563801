$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --bar-height: 10px;

  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: var(--bar-height);
  background-color: var(--color-gray-100);
  opacity: 0;
  transform: translate(0, var(--bar-height));
  transition:
    transform var(--100ms) var(--ease-out),
    opacity var(--100ms) var(--ease-out);

  &.active {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: 1.75s;
  }
}

.progress {
  position: absolute;
  background-color: var(--color-gray-300);
  inset: 0;
}
