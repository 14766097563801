$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --color-prefix: var(--form-field-placeholder-color);

  position: relative;
  z-index: 0;
  display: grid;
  grid-template: min-content min-content min-content / min-content auto;
  grid-template-areas:
    "label label"
    "prefix field"
    "footer footer";

  .field {
    width: 100%;
    max-width: 100%;
    min-height: var(--form-field-height);
    box-sizing: border-box;
    padding: 0 var(--form-field-padding-x);
    border: none;
    border-radius: var(--form-field-border-radius);
    appearance: none;
    background-color: transparent;
    color: var(--form-field-color);
    font-family: inherit;
    font-size: var(--form-field-font-size);
    font-weight: 500;
    grid-area: field;
    letter-spacing: inherit;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-clip: text;
      -webkit-text-fill-color: var(--form-field-color);
    }

    &::placeholder {
      color: var(--form-field-placeholder-color);
    }

    @media (hover: hover) {
      &:hover {
        --form-field-border-color: var(--form-field-border-color-hover);

        ~ .bg {
          --form-field-border-color: var(--form-field-border-color-hover);
          --form-field-background-color: var(
            --form-field-background-color-hover
          );
          --form-field-background-opacity: var(
            --form-field-background-opacity-hover
          );
        }
      }
    }
  }

  &.focus {
    --color-prefix: var(--form-field-color);

    &,
    * {
      --form-field-border-color: var(
        --form-field-border-color-focus
      ) !important;
    }

    .bg {
      --form-field-background-color: var(
        --form-field-background-color-focus
      ) !important;
      --form-field-background-opacity: var(
        --form-field-background-opacity-focus
      ) !important;
    }
  }

  &.status-invalid:not(.focus) {
    --form-field-border-color: var(--form-field-border-color-warn) !important;
  }

  &.status-valid:not(.focus) {
    --form-field-border-color: var(--form-field-border-color-ok) !important;
  }

  .bg {
    position: relative;
    z-index: -1;
    overflow: hidden;
    border-radius: var(--form-field-border-radius);
    background-color: hsla(
      var(--form-field-background-color),
      var(--form-field-background-opacity)
    );
    grid-area: field-start / field-start / footer-end / footer-end;
    transition: background-color var(--form-field-transition-duration-hover)
      var(--ease-out);

    &::before {
      position: absolute;
      border: solid var(--form-field-border-width)
        var(--form-field-border-color);
      border-radius: inherit;
      content: "";
      inset: 0.5px;
      pointer-events: none;
      transition: border-color var(--form-field-transition-duration-hover)
        var(--ease-out);

      /* position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--form-field-border-width);
      background-color: var(--form-field-border-color);
      content: "";
      grid-area: prefix-start / prefix-start / footer-end / footer-end;
      transition:
        background-color var(--form-field-transition-duration-hover)
        var(--ease-out); */
    }
  }

  .highlight {
    position: absolute;
    overflow: hidden;
    padding: var(--form-field-highlight-width);
    border: solid 0 transparent;
    border-radius: var(--form-field-border-radius);
    content: "";
    grid-area: field-start / field-start / footer-end / footer-end;
    inset: 0;
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    transition:
      opacity var(--form-field-transition-duration-hover) var(--ease-out),
      transform var(--form-field-transition-duration-hover) var(--ease-out);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      content: "";
    }
  }

  .prefix {
    display: flex;
    align-items: center;
    margin-top: 0.1em;
    color: var(--color-prefix);
    font-size: var(--form-field-font-size);
    grid-area: prefix;
    transition: color var(--form-field-transition-duration-hover)
      var(--ease-out);

    &:not(:empty) {
      padding-left: var(--form-field-padding-x);
      margin-right: calc(var(--form-field-padding-x) * -0.5);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: var(--form-field-padding-x);
    color: var(--form-field-placeholder-color);
    font-size: 0.8em;
    grid-area: footer;
  }

  .label {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: var(--form-label-margin);
    color: var(--form-label-color);
    font-size: var(--form-label-font-size);
    font-weight: 500;
    grid-area: label;
    transition: color var(--form-field-transition-duration-hover)
      var(--ease-out);

    > span {
      position: relative;
      display: flex;
      align-items: center;

      span {
        position: relative;
        margin-left: 0.5em;
        color: var(--form-field-placeholder-color);
        font-size: 0.85em;
      }
    }
  }

  &.theme-a {
    .field {
      min-height: calc(var(--form-field-height) * 1.3);
      padding-top: calc(var(--form-field-height) * 0.5);
      grid-area: label-start / field / label-start / field;

      &[value=""]:not(:focus, :placeholder-shown, .hasPrefix) ~ .label {
        color: var(--form-field-placeholder-color);
        font-size: var(--form-field-font-size);
        transform: translate3d(0, calc(var(--form-field-height) * 0.5), 0);

        > span {
          color: var(--form-field-placeholder-color);
          transform: translate3d(0, -50%, 0);
        }
      }
    }

    .prefix {
      padding-top: calc(var(--form-field-height) * 0.5);
      grid-area: label-start / prefix / label-start / prefix;
    }

    .bg {
      grid-area: label-start / footer-start / footer / footer;
    }

    .highlight {
      padding: 0 0 var(--form-field-highlight-width);
      grid-area: label-start / footer-start / footer / footer;
    }

    .label {
      position: relative;
      height: calc(var(--form-field-height) * 0.5);
      padding: var(--form-label-margin) var(--form-field-padding-x)
        var(--form-label-margin);
      pointer-events: none;
      transition:
        color var(--form-field-transition-duration-hover) var(--ease-out),
        transform var(--form-field-transition-duration-hover) var(--ease-out),
        font-size var(--form-field-transition-duration-hover) var(--ease-out);

      > span {
        transition: transform var(--form-field-transition-duration-hover)
          var(--ease-out);
      }
    }
  }
}
