$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.step {
  display: none;

  &.active {
    display: block;
  }
}

.label {
  position: relative;
  padding: 0.5rem 0;
  margin-bottom: 2rem;

  .title {
    position: relative;
  }
}
