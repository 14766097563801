$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  transition: opacity var(--250ms) var(--ease-out);

  &.checked {
    opacity: 1;
  }

  @include hoverFocus {
    opacity: 1;
  }
}

.label {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0.15em 1em;
  cursor: pointer;
  font-size: 0.85em;
  font-weight: 500;
}

.field {
  position: absolute;
  appearance: none;
  visibility: hidden;
}
