$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.group {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-auto-flow: dense;

  &:not(:last-child) {
    margin-bottom: var(--form-group-margin);

    &.margin-lg {
      grid-gap: calc(var(--form-group-margin) * 1.2);
    }
  }

  &.display-stacked {
    grid-gap: 0em;
  }

  &.display-inline {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  &.display-search {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: auto min-content;

    button {
      height: 100%;
    }
  }
}
