$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.spacer {
  position: relative;
  margin: var(--form-spacer-size) 0;

  span {
    position: relative;
    display: block;
    height: 1px;
  }
}
