$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: fixed;
  bottom: 2rem;
  left: var(--page-padding);
  pointer-events: none;

  &.active .display {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.display {
  position: relative;
  display: flex;
  max-width: 300px;
  flex-direction: column;
  padding: 1rem 1.2rem;
  border: solid 1px rgba(255, 255, 255, 0.025);
  border-radius: 2rem;
  backdrop-filter: blur(5px);
  background: linear-gradient(
    to bottom,
    rgba(50, 50, 50, 0.75),
    rgba(100, 100, 100, 0.5)
  );
  color: var(--color-gray-800);
  gap: 1rem;
  opacity: 0;
  pointer-events: all;
  transform: translate(0, 5rem) rotate(10deg);
  transform-origin: 0% 0;
  transition:
    opacity var(--300ms) var(--ease-out),
    transform var(--300ms) var(--ease-out);

  h1 {
    margin: 0;
    color: var(--color-white);
    font-size: var(--font-size-sm);
    text-transform: uppercase;
  }

  p {
    margin: 0 0 0.5rem;
    font-size: 0.95em;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    transition: color var(--250ms) var(--ease-out);

    @media (hover: hover) {
      &:hover {
        color: var(--color-white);
      }
    }
  }
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
