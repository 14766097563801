$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  &.layout-simple {
    display: flex;
    flex-direction: column;
    grid-gap: var(--grid-gap);
  }

  &.layout-grid {
    display: grid;
    grid-gap: max(8px, var(--grid-gap) * 0.5) calc(var(--grid-gap) * 0.5);
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

    @include mediaBreakpointDown("xs") {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }

  &.layout-flex {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: max(8px, var(--grid-gap) * 0.75) calc(var(--grid-gap) * 0.75);

    > * {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;

      > * {
        flex-grow: 1;
      }
    }
  }
}
