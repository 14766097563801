$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  margin: 2rem 0;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submitError {
  width: 100%;
  padding: 0.5em 0 0;
  color: var(--color-d-accent-b);
  font-size: 0.9em;
}

.navigation {
  display: grid;
  margin-top: 0.4rem;
  font-size: 1rem;
  grid-gap: var(--grid-gap);
  grid-template: 1fr / 1fr 1fr;

  > div {
    display: flex;

    &:nth-child(2) {
      justify-content: flex-end;
    }
  }
}

.button {
  min-width: 8em;
}
