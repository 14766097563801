$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --arrow-width: 2em;

  grid-template: min-content min-content / 1fr min-content;
  grid-template-areas:
    "label label"
    "field arrow";

  &.theme-a {
    .bg,
    .highlight,
    .field {
      grid-area: label-start/field/label-start/arrow-end !important;
    }

    .arrow {
      padding-top: calc(var(--form-field-height) * 0.5);
      grid-area: label-start / arrow-start;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  color: var(--color-c-accent-a);
  font-weight: 500;
  grid-area: field;
  letter-spacing: 0.05em;
  opacity: 0;
  pointer-events: none;

  svg {
    width: 0.9em;
    height: auto;
  }

  span,
  svg {
    margin-right: 0.33em;
    color: var(--color-c);
  }
}

.bg,
.highlight,
.field {
  grid-area: field / field / arrow / arrow !important;
}

.field {
  padding-right: var(--arrow-width) !important;
  cursor: pointer;
  line-height: inherit;
  pointer-events: all;
}

.arrow {
  display: flex;
  width: var(--arrow-width);
  align-items: center;
  justify-content: center;
  grid-area: arrow;
  pointer-events: none;

  svg {
    width: 0.8em;
    height: auto;
    color: var(--form-select-arrow-color);
    transition: color var(--250ms) var(--ease-out);
  }
}
