$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-height: var(--modal-max-height);
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 1.1rem;
  opacity: 0;
  pointer-events: all;
  transform: translate(0, 1rem);
  transition:
    opacity var(--250ms) var(--ease-out),
    transform var(--250ms) var(--ease-out);

  &.active {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.scroll {
  overflow: hidden auto;
  width: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 2rem 2rem;

  @include mediaBreakpointDown("sm") {
    padding: 0 1rem;
  }

  @include mediaBreakpointDown("xs") {
    padding: 0 0.5rem;
  }
}
