$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  --local-padding: 2rem 1rem 2rem;

  display: flex;
  overflow: hidden;
  width: 700px;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  padding: var(--local-padding);
  border-radius: 1rem;
  background-color: var(--color-white);
  color: var(--color-black);
  gap: 1rem;
  opacity: 0;
  pointer-events: all;
  transform: translate(0, 5rem) rotate(-10deg);
  transform-origin: 100% 0;
  transition:
    opacity var(--300ms) var(--ease-out),
    transform var(--300ms) var(--ease-out);

  @include mediaBreakpointDown("xxs") {
    --local-padding: 1.5rem 0.5rem 0.5rem;
  }

  &.active {
    opacity: 1;
    transform: translate(0, 0);
  }
}
