$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  .field {
    height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 1em;
    margin-top: calc(var(--form-field-height) * 0.75);
    resize: none;
  }

  .count {
    span:first-child {
      &::after {
        position: relative;
        display: inline-block;
        padding: 0 0.2em;
        content: "/";
      }
    }
  }
}
