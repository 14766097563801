$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  text-transform: uppercase;

  --local-text-roll: 0;
  --dot-size: 6px;

  @include hoverFocus {
    --local-text-roll: 1;
  }

  @include mediaBreakpointDown("sm") {
    --dot-size: 5px;
  }

  &.active {
    .dots {
      transform: rotate(270deg);
    }
  }
}

.label {
  display: flex;
  overflow: hidden;

  span {
    line-height: 1.2em;
    text-shadow: 0 1.2em currentcolor;
    transform: translate(0, calc(-100% * var(--local-text-roll)));
    transition: transform var(--300ms) var(--ease-out);
  }
}

.dots {
  position: relative;
  width: 1em;
  height: 1em;
  transition: transform var(--500ms) var(--ease-out);

  &::before,
  &::after {
    position: absolute;
    left: calc(50% - var(--dot-size) * 0.5);
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    background-color: currentcolor;
    content: "";
  }

  &::after {
    bottom: 0;
  }
}
