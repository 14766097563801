$assetsDir: "https://ik.imagekit.io/metropolis/hub-assets"; @use "/src/app/styles/imports" as *;
.container {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  padding: 0 var(--page-padding);
  pointer-events: none;

  a,
  button {
    pointer-events: all;
  }
}

.bar {
  position: relative;
  display: flex;
  width: 100%;
  max-width: var(--page-max-width);
  height: var(--header-height);
  align-self: stretch;
  margin: 0 auto;
}

.links {
  position: relative;
  display: flex;
  width: 100%;
  align-self: stretch;
  justify-content: space-between;

  .group {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 0;
    margin: 0;
    gap: 1.5rem;
    list-style: none;

    &.right {
      margin-right: calc(-1rem - 3px);
    }
  }

  .link {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .link:hover .dropdown {
    opacity: 1;
    pointer-events: all;
    transition:
      visibility var(--250ms) var(--ease-out),
      opacity var(--250ms) var(--ease-out);
    visibility: visible;

    .box {
      transform: translate3d(0, 0, 0);
      transition: transform var(--250ms) var(--ease-out);
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0.5rem 0 0;
    opacity: 0;
    pointer-events: none;
    transition:
      visibility var(--250ms) var(--250ms) var(--ease-out),
      opacity var(--250ms) var(--250ms) var(--ease-out);
    visibility: hidden;

    .box {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: var(--color-gray-200);
      transform: rotate(10deg) translate3d(0, 1rem, 0);
      transform-origin: 0 0;
      transition: transform var(--250ms) var(--250ms) var(--ease-out);
    }
  }

  @include mediaBreakpointDown("lg") {
    display: none;
  }
}

.logo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;

  .full {
    width: 8rem;
    fill: currentcolor;
  }

  .icon {
    display: none;
    width: min(calc(100% - 5rem), 11rem);
    fill: currentcolor;

    @include mediaBreakpointDown("sm") {
      width: min(calc(100% - 9rem), 14rem);
    }
  }

  a {
    display: block;
    color: var(--color-link-primary);
    transition: color var(--250ms) var(--ease-out);

    @include hoverFocus {
      color: var(--color-link-primary-hover);
    }
  }

  svg {
    display: block;
  }

  @include mediaBreakpointDown("lg") {
    justify-content: flex-start;

    .full {
      display: none;
    }

    .icon {
      display: block;
    }
  }
}

.burger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;

  @include mediaBreakpointDown("lg") {
    display: flex;
  }
}
